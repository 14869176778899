import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'rsuite'

const About = () => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Link to='/'><Button appearance='primary'><FontAwesomeIcon icon={FA.faHome} /></Button></Link><h1 className='title-h1'>О программе</h1>
      <hr />
      <div className='about-text'>
        <p>Настоящее исследование финансировался Комитетом науки Министерства науки и высшего образования Республики Казахстан. Грант № BR18574227 - Научно-прикладное обоснование управления ПСС по предотвращениюпроцессов опустынивания в южных регионах Казахстана, для обеспечения устойчивого развития сельских территорий.</p>
        <p>Целю данной программы является разработка научно-прикладного обоснования устойчивого управления природно-сельскохозяйственными системами по предотвращению развития процессов опустынивания в южных регионахКазахстана, которое позволит устойчивое развитие сельского хозяйства и сельских территорий, а также высокий уровень жизни населения.</p>
        <p>Опустынивание является глобальной проблемой, охватывающий аридные и семиаридные разработка территории земной суши. Данный процесс оказывает сильнейшее влияние на устойчивое развитие регионов, негативновлияет на биоразнообразие экосистем, их экологическую безопасность.</p>
        <p>Последствия опустынивания усугубляются изменением климата и растущими антропогенными нагрузками. В соответствии с климатическими прогнозами, заметный рост температуры, неравномерное распределение осадков,интенсивное таяние ледников – все это в долгосрочной перспективе приведет к усилению процесса опустынивания.</p>
        <p>На данный момент более 2/3 территории Центральной Азии представляет собой засушливые земли. К примеру, в Казахстане, по подсчетам Всемирного Банка, опустыниванию подвержено 66% территории страны.</p>
        <p>В настоящее время в Казахстане существует потребность в совершенствовании системы управления земельными ресурсами в целях снижения бедности и достижения устойчивого развития страны. Приоритетным являетсяснижение сельской бедности в районах, пострадавших от опустынивания.</p>
        <p>Концепция перехода Республики Казахстан к «зеленой экономике» рассматривает опустынивание как серьезный повод для беспокойства и предусматривает придерживаться принципов «зеленого» сельского хозяйства,которые обеспечат развитие сектора и в то же время позволят сохранить и улучшить окружающую среду. Проблема опустынивания отражены в государственных программах по развитию регионов, задачах Целейустойчивого развития, «Стратегической мере по борьбе с опустыниванием в Республике Казахстан до 2025 года» и Национальном проекте по развитию агропромышленного комплекса Республики Казахстан на 2021-2025годы.</p>
        <p><b>Объектом исследования</b> является южный регион Республики Казахстан. Ландшафты региона предрасположены к опустыниванию, которое начало стремительно нарастать на фоне климатических изменений и ростаантропогенной нагрузки. Серьезной проблемой в развитии большинства сельских населенных пунктов южного региона является перегрузка пастбищ – результат увеличения поголовья скота в личных подворьях и другихсельскохозяйственных формированиях. Орошаемые земли региона подвержены засолению и деградации. Все это приводит к ухудшению экологической обстановки и деградации земель, в результате которых происходитактивизация процессов опустынивания.</p>
        <p>Решение вышеуказанных проблем мы видим в разработке научно-прикладного обоснования устойчивого управления природно-сельскохозяйственными системами по предотвращению развития процессов опустынивания в южныхрегионах Казахстана, позволяющее обеспечить экономическое развитие, продовольственную безопасность и высокий уровень жизни населения.</p>
        <p>Результаты исследования могут быть использованы как эффективный механизм по управлению природно-сельскохозяйственными системами.</p>
      </div>
    </div>
  )
}

export default About
