import { Link } from 'react-router-dom/cjs/react-router-dom'

const SideMain = () => {
  return (
    <div id="sidebar" className="open">
      <div id="layers" className="show">
      <ul className="themes-list clearfix" style={{ margin: 0, width: '100%' }}>
        <li>
          <img src='logo2.png' width='100%' />
        </li>
        <li>
          <img src='logo3.png' width='100%' />
        </li>
        </ul>
        <div className="tab-content">
        <h2>КАТАЛОГ</h2>
          <div id="catalog" className="tab-pane active">
            <div className="ng-isolate-scope">
              <div
                id="themes-content"
                className="themes-switcher collapse in"
                aria-expanded="true"
              >
                <ul className="themes-list clearfix">
                  <li className="theme-tourisme">
                    <Link style={{ fontSize: '15px' }} to='/scibase'>Научно-методологические основы</Link>
                  </li>
                  <li className="theme-main">
                    <Link style={{ fontSize: '15px' }} to='/db'>База данных</Link>
                  </li>
                  <li className="theme-eau">
                    <Link style={{ fontSize: '15px' }} to='/sensing'>Данные ДЗЗ</Link>
                    {/* <a style={{ fontSize: '15px' }} href='https://rsgiskaznu.maps.arcgis.com/apps/instant/sidebar/index.html?appid=bb853afacaa34044830069057bc7c570' target='_blank' rel="noreferrer">Данные ДЗЗ</a> */}
                  </li>
                  <li className="theme-emwelt">
                    <Link style={{ fontSize: '15px' }} to='/field'>Полевые исследования</Link>
                  </li>
                  <li className="theme-pag">
                    <Link style={{ fontSize: '15px' }} to='/maps'>Картографирование</Link>
                  </li>
                  <li className="theme-lenoz">
                    <Link style={{ fontSize: '15px' }} to='/eval'>Оценка</Link>
                  </li>
                  <li className="theme-agriculture">
                    <Link style={{ fontSize: '15px' }} to='/recs'>Рекомендации и мероприятия</Link>
                  </li>
                  <li className="theme-at">
                    <Link style={{ fontSize: '15px' }} to='/sem'>Семинары и тренинги</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}

export default SideMain
