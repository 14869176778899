import L from 'leaflet'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MapControls from '../map/MapControls'
import PageRES from './components/PageRES'

const MapRES = () => {
  const [sel, setSel] = useState({
    year: undefined,
    place: undefined,
    index: undefined
  })
  const [mapType, setMap] = useState('https://tile.openstreetmap.org/{z}/{x}/{y}.png')

  const { t, i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const mapRef = useRef(null)
  const paneRef = useRef(null)
  const drawnLayerRef = useRef(null)
  const showLayerRef = useRef(null)
  const tileLayerRef = useRef(null)
  const searchGeometryRef = useRef(null)

  let map

  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.237257, 69.9804824],
        zoom: 5,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)

      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)

      L.tileLayer(`https://tiles-ap1.arcgis.com/Iiwz1ZQng7a0HggY/arcgis/rest/services/${sel.index + sel.year + sel.place}/MapServer/WMTS/tile/1.0.0/${sel.index + sel.year + sel.place}/default/default028mm/{z}/{y}/{x}`, {
        minNativeZoom: 6,
        maxNativeZoom: 13,
        id: 'tiles'
      }).addTo(tileLayerRef.current)

      mapRef.current = map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])

  useEffect(() => {
    tileLayerRef.current.clearLayers()
    if (sel.index && sel.place && sel.year) {
      L.tileLayer(`https://tiles-ap1.arcgis.com/Iiwz1ZQng7a0HggY/arcgis/rest/services/${sel.index + sel.year + sel.place}/MapServer/WMTS/tile/1.0.0/${sel.index + sel.year + sel.place}/default/default028mm/{z}/{y}/{x}`, {
        minNativeZoom: 6,
        maxNativeZoom: 13,
        id: 'tiles'
      }).addTo(tileLayerRef.current)
    }
  }, [sel])

  return (
    <PageRES sel={sel} setSel={setSel}/>
  )
}
export default MapRES
