import { range } from 'lodash'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { SelectPicker } from 'rsuite'

const SideRES = ({ sel, setSel }) => {
  const DB = {
    years: range(1990, 2024).map(y => ({ label: y, value: y })),
    places: [
      { label: 'Кызылординская', value: 'kzl' },
      { label: 'Туркестанская', value: 'tur' },
      { label: 'Жамбылская', value: 'jam' },
      { label: 'Алматинская', value: 'alm' },
      { label: 'Жетысу', value: 'jet' }
    ],
    indices: [
      { label: 'NDVI', value: 'ndvi' },
      { label: 'NDWI', value: 'ndwi' },
      { label: 'NDMI', value: 'ndmi' },
      { label: 'EVI', value: 'evi' },
      { label: 'NBR', value: 'nbr' },
      { label: 'SAVI', value: 'savi' },
      { label: 'MSAVI', value: 'msavi' },
      { label: 'BSI', value: 'bsi' },
      { label: 'TGSI', value: 'tgsi' },
      { label: 'PDSI', value: 'pdsi' }
    ]
  }
  return (
  <div id="sidebar" className="open">
    <div id="layers" className="show">
    <ul className="themes-list clearfix" style={{ margin: 0, width: '100%' }}>
        <li>
          <img src='logo2.png' width='100%' />
        </li>
        <li>
          <img src='logo3.png' width='100%' />
        </li>
        </ul>
      <div className="tab-content">
      <h2>Данные ДЗЗ</h2>
        <div id="catalog" className="tab-pane active">
          <div className="ng-isolate-scope">
            <div
              id="themes-content"
              className="themes-switcher collapse in"
              aria-expanded="true"
            >
              <ul className="themes-list clearfix database">
                <li className="theme-agriculture">
                  <Link style={{ fontSize: '25px' }} to='/'>&lt;&lt; Назад</Link>
                </li>
                <li className='selects'>
                  <SelectPicker placeholder='Год' data={DB.years} searchable={false} value={sel.year} onChange={e => setSel({ ...sel, year: e })}/>
                  <SelectPicker placeholder='Область' data={DB.places} searchable={false} value={sel.place} onChange={e => setSel({ ...sel, place: e })}/>
                  <SelectPicker placeholder='Индекс' data={DB.indices} searchable={false} value={sel.index} onChange={e => setSel({ ...sel, index: e })}/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default SideRES
