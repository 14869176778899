import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Auth from '../Auth'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const Header = () => {
  const [openAuth, setOpenAuth] = useState(false)
  const { t, i18n } = useTranslation()

  return (
<>
<header
        className="navbar navbar-default navbar-fixed-top"
        role="navigation"
      >
        <div className="container-fluid">
          <div className="navbar-inner">
            <div className="pull-left brand">
              <p style={{ fontSize: 23 }}>
                <img src="logo1.png" alt="" height='55px' style={{ margin: '0 10px' }} />
                Устойчивое управление природно-сельскохозяйственными системами
              </p>
            </div>
          </div>
          <div className="ng-isolate-scope">
            <div className="search-container">
              <form className="navbar-form ng-pristine ng-valid" role="search">
                <div className="form-group">
                  <span
                    className="twitter-typeahead"
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <input
                      type="text"
                      autoCorrect="off"
                      autoCapitalize="none"
                      className="form-control tt-input"
                      placeholder="Поиск ... "
                      spellCheck="false"
                      dir="auto"
                      aria-activedescendant=""
                      aria-owns="undefined_listbox"
                      role="combobox"
                      aria-readonly="true"
                      aria-autocomplete="list"
                      style=
                      {{
                        position: 'relative',
                        verticalAlign: 'top',
                        backgroundColor: 'transparent'
                      }}

                    />
                  </span>
                  <span className="clear-button">✕</span>
                  <button className="btn btn-link facet-toggle">
                    <FontAwesomeIcon icon={FA.faSearch} color='white' />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <ul className="nav navbar-nav pull-right" style={{ display: 'flex' }}>
            <li className="hidden-sm" style={{ height: '50px' }}>
              <Link
                to="/about"
                style={{ fontSize: 24, height: '100%', borderLeft: '1px solid black', textIndent: 0, padding: '18px 10px 10px 10px' }}
              >
                О программе
              </Link>
            </li>
            <li className="lang icon">
              <a onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} className="ng-pristine ng-untouched ng-valid ng-not-empty" style={{ textIndent: 40, width: 75, textAlign: 'left', display: 'flex', alignItems: 'center', height: 50, padding: 0, cursor: 'pointer', userSelect: 'none' }}>
                <span style={{ fontSize: 30 }}>{i18n.language.toLocaleUpperCase()}</span>
              </a>

            </li>

            <li className="user icon" onClick={() => setOpenAuth(true)}>
              <a className="ng-pristine ng-untouched ng-valid ng-not-empty">
                User
              </a>
            </li>
          </ul>

        </div>
      </header>
{openAuth && <Auth close={() => setOpenAuth(false)} />}
</>
  )
}

export default Header
