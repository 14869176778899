import Cookies from 'js-cookie'
import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { validate } from './http/API'
import MapNew from './pages/MapNew'
import MapField from './pages/MapField'
import SciBase from './pages/SciBase'
import MapDatabase from './pages/db/MapDatabase'
import Auth from './pages/Auth'
import MapHydro from './pages/db/MapHydro'
import About from './pages/About'
import MapMeteo from './pages/db/MapMeteo'
import MapRES from './pages/MapRES'

function App () {
  const token = Cookies.get('token')
  const startup = async () => {
    if (token) {
      await validate().then().catch(e => console.log(e))
    }
  }
  const authRedirect = () => Auth({ close: () => window.location.reload() })
  startup()
  return (
    <>
      <Router>
        <Switch>
          <Route path='/scibase' component={token ? SciBase : authRedirect} />
          <Route path='/field' component={token ? MapField : authRedirect} />
          <Route path='/db' component={token ? MapDatabase : authRedirect} />
          <Route path='/db-hydro' component={token ? MapHydro : authRedirect} />
          <Route path='/db-meteo' component={token ? MapMeteo : authRedirect} />
          <Route path='/db-hydres' component={token ? MapDatabase : authRedirect} />
          <Route path='/db-eco' component={token ? MapDatabase : authRedirect} />
          <Route path='/db-farm' component={token ? MapDatabase : authRedirect} />
          <Route path='/sensing' component={token ? MapRES : authRedirect} />
          <Route path='/about' component={About} />
          <Route exact path='*' component={MapNew} />
        </Switch>
      </Router>
    </>
  )
}

export default App
