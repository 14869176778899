/* eslint-disable prefer-regex-literals */
import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Icon, Input, InputGroup, Modal } from 'rsuite'
import { authUser, regUser } from '../http/API'

const Auth = ({ close }) => {
  const { t } = useTranslation()
  const [openReg, setOpenReg] = useState(false)
  const [openLogin, setOpenLogin] = useState(!Cookies.get('token'))
  const [openUser, setOpenUser] = useState(Cookies.get('token'))
  const [datareg, setDataReg] = useState({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    full_name: '',
    organization: ''
  })
  const [datalogin, setDataLogin] = useState({
    username: '',
    password: ''
  })
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)
  const user = Cookies.get('user') && JSON.parse(Cookies.get('user'))
  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }
  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('refresh')
    Cookies.remove('user_id')
    Cookies.remove('user')
    window.location.reload()
  }
  const onSubmitLogin = async () => {
    const tempErrors = validate(datalogin)
    if (Object.keys(tempErrors).length === 0) {
      setLoading(true)
      try {
        await authUser(datalogin).then((res) => {
          setLoading(false)
          if (res.status.toString()[0] === '2') {
            Alert.success('Добро пожаловать!')
            close()
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(Object.values(e.response.data))
        // Alert.error(t('error.noconnection'))
      }
    } else {
      Object.values(tempErrors).map(e => Alert.error(e))
    }
  }
  const onSubmitReg = async () => {
    const tempErrors = validate(datareg)
    if (Object.keys(tempErrors).length === 0) {
      try {
        await regUser(datareg).then(async (res) => {
          setLoading(false)
          if (res.status.toString()[0] === '2') {
            Alert.success('Успешная регистрация!')
            await authUser({ username: datareg.username, password: datareg.password }).then(res => {
              close()
            })
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(Object.values(e.response.data))
        // Alert.error(t('error.connectionerror'))
      }
    } else {
      Object.values(tempErrors).map(e => Alert.error(e))
    }
  }

  const validate = (data) => {
    const errors = {}
    const emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'i')
    if (!data.username) {
      errors.email = t('Errors.empty_username')
    }
    if (data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('Errors.valid_email')
    }
    if (!data.password) {
      errors.password = t('Errors.empty_password')
    } else if (!passwordPattern.test(data.password)) {
      errors.password = t('Errors.valid_password')
    }
    return errors
  }

  return (
    <>
      <Modal show={openLogin} onHide={close} backdrop='static' size='lg'>
        <Modal.Header>
          <Modal.Title>Вход</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Войти в существующий аккаунт:</h5>
          <br />
          <Input placeholder='Логин *' value={datalogin.username} onChange={v => setDataLogin({ ...datalogin, username: v })}/>
          <br />
          <InputGroup inside>
            <Input
              placeholder={t('common.password')} size='md'
              type={passwordType}
              value={datalogin.password} onChange={v => setDataLogin({ ...datalogin, password: v })}
            />
            <InputGroup.Addon>
              <Icon icon={eye} onClick={togglePassword} />
            </InputGroup.Addon>
          </InputGroup>
          <br />
          <Button appearance='primary' onClick={onSubmitLogin}>Войти</Button>
          <br />
          <br />
          <a className='pointer' onClick={() => { setOpenLogin(false); setOpenReg(true) }}>Создать новый аккаунт</a>
        </Modal.Body>
      </Modal>
      <Modal show={openReg} onHide={close} backdrop='static' size='lg'>
        <Modal.Header>
          <Modal.Title>Регистрация</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Создать новый аккаунт</h5>
          <br />
          <Input placeholder='Логин *' value={datareg.username} onChange={v => setDataReg({ ...datareg, username: v })}/>
          <br />
          <Input placeholder='Email *' value={datareg.email} onChange={v => setDataReg({ ...datareg, email: v })}/>
          <br />
          <InputGroup inside>
            <Input
              placeholder={t('common.password')} size='md'
              type={passwordType}
              value={datareg.password}
              onChange={e => setDataReg({
                ...datalogin,
                password: e
              })}
            />
            <InputGroup.Addon>
              <Icon icon={eye} onClick={togglePassword} />
            </InputGroup.Addon>
          </InputGroup>
          <br />
          <Input placeholder='Имя и фамилия *' value={datareg.full_name} onChange={v => setDataReg({ ...datareg, full_name: v })}/>
          <br />
          <Input placeholder='Организация *' value={datareg.organization} onChange={v => setDataReg({ ...datareg, organization: v })}/>
          <br />
          <Button appearance='primary' onClick={onSubmitReg}>Зарегистрироваться</Button>
          <br />
          <br />
          <a className='pointer' onClick={() => { setOpenLogin(true); setOpenReg(false) }}>Войти в существующий аккаунт</a>
        </Modal.Body>
      </Modal>
      {user &&
      <Modal show={openUser} onHide={close} backdrop='static' size='lg'>
        <Modal.Header>
          <Modal.Title>Ваш аккаунт</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{user.full_name}</h5>
          <br />
          <p>Почта: {user.email}</p>
          <p>Организация: {user.organization}</p>
          <p>Дата и время регистрации: {new Date(user.date_joined).toLocaleString('ru')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={logout}>Выйти из аккаунта</Button>
        </Modal.Footer>
      </Modal>}
    </>

  )
}

export default Auth
