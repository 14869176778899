import { Link } from 'react-router-dom/cjs/react-router-dom'

const SideDB = () => {
  return (
  <div id="sidebar" className="open">
    <div id="layers" className="show">
    <ul className="themes-list clearfix" style={{ margin: 0, width: '100%' }}>
        <li>
          <img src='logo2.png' width='100%' />
        </li>
        <li>
          <img src='logo3.png' width='100%' />
        </li>
        </ul>
      <div className="tab-content">
      <h2>База данных</h2>
        <div id="catalog" className="tab-pane active">
          <div className="ng-isolate-scope">
            <div
              id="themes-content"
              className="themes-switcher collapse in"
              aria-expanded="true"
            >
              <ul className="themes-list clearfix database">
                <li className="theme-agriculture">
                  <Link style={{ fontSize: '25px' }} to='/'>&lt;&lt; Назад</Link>
                </li>
                <li className="theme-main">
                  <Link style={{ fontSize: '25px' }} to='/db-hydro'>Природа. Гидрология</Link>
                </li>

                <li className="theme-tourisme">
                  <Link style={{ fontSize: '21px' }} to='/db-meteo'>Природа. Метеорология</Link>
                </li>
                <li className="theme-pag">
                  <a style={{ fontSize: '18px' }} href='https://rsgiskaznu.maps.arcgis.com/apps/mapviewer/index.html?layers=b114f8e6771c41b2b2f13be3fc7ac763' target='_blank' rel="noreferrer">Природа. Водные ресурсы</a>
                  {/* <Link style={{ fontSize: '18px' }} to='/db-hydres'>Природа. Водные ресурсы</Link> */}
                </li>

                <li className="theme-emwelt">
                  <Link style={{ fontSize: '30px' }} to='/db-eco'>Экология</Link>
                </li>

                <li className="theme-eau">
                  <Link style={{ fontSize: '25px' }} to='/db-farm'>Сельское хозяйство</Link>
                </li>

                <li className="theme-pag" style={{ visibility: 'hidden' }}>
                  <a />
                </li>
                <li className="theme-pag" style={{ visibility: 'hidden' }}>
                  <a />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default SideDB
