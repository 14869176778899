
const MapWrapper = () => {
  return (
<div className="map-wrapper">
          <app-map className="ng-isolate-scope">
            <div
              className="app-map mapboxgl-map blankBackground"
              style={{ cursor: 'default' }}
            >
              <div
                className="ol-viewport"
                style=
                {{
                  position: 'relative',
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%'
                }}
                id='map'
              >
              </div>
            </div>
          </app-map>
        </div>
  )
}

export default MapWrapper
