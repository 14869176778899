import React, { createContext, Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'leaflet-geosearch/dist/geosearch.css'
import './styles/index.css'
import './styles/main.css'
// import './styles/side.css'
import { CookiesProvider } from 'react-cookie'
import './i18n'
import 'rsuite/dist/styles/rsuite-default.css'

export const Context = createContext(null)
// TODO: get rid of unneccessary redux
// require('dotenv').config()
ReactDOM.render(
  <Suspense fallback={(<div>Loading</div>)}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Suspense>,
  document.getElementById('root')
)
