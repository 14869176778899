import L from 'leaflet'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw-src.css'

const MapControls = (map, t) => {
  try {
    L.control.scale({
      position: 'bottomleft',
      backgroundColor: '#222222'
    }).addTo(map)
    L.control.zoom({
      position: 'topleft'
    }).addTo(map)
    const editableLayers = new L.FeatureGroup()
    map.addLayer(editableLayers)
    const drawPluginOptions = {
      position: 'topleft',
      draw: {
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          precision: {
            km: 1,
            ha: 1
          },
          drawError: {
            color: '#00FF00', // '#010101', // Color the shape will turn when intersects
            message: '<strong>Polygon draw does not allow intersections!<strong>' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#00FF00' // '#fefefe'
          }
        },
        circle: false, // Turns off this drawing tool
        rectangle: {
          shapeOptions: {
            clickable: false,
            color: '#00FF00' // '#fefefe'
          }
        },
        marker: true,
        circlemarker: false,
        polyline: false
      }
    }
    // Initialise the draw control and pass it the FeatureGroup of editable layers
    const drawControl = new L.Control.Draw(drawPluginOptions)
    map.addControl(drawControl)
  } catch (e) {

  }
}
export default MapControls
