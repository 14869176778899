import Header from './Header'
import MapWrapper from './MapWrapper'
import SideDB from './SideDB'

const PageDB = () => {
  return (
    <div
      data-theme="main"
      className="lux-body no-touch full"
      style={{ cursor: 'auto' }}
    >
      <Header />
      <div id="main-container">
        <SideDB />
        <MapWrapper />
      </div>
    </div>
  )
}
export default PageDB
