import Header from './Header'
import MapWrapper from './MapWrapper'
import SideRES from './SideRES'

const PageRES = ({ sel, setSel }) => {
  return (
    <div
      data-theme="main"
      className="lux-body no-touch full"
      style={{ cursor: 'auto' }}
    >
      <Header />
      <div id="main-container">
        <SideRES sel={sel} setSel={setSel}/>
        <MapWrapper />
      </div>
    </div>
  )
}
export default PageRES
